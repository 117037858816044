import React, { useEffect, useState, useCallback, useRef } from "react";
import clsx from "clsx";
import { Grid, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import ValetRequestMonitorTable from "./ValetRequestMonitorTable/ValetRequestMonitorTable";
import ValetRequestMonitorSearch from "./ValetRequestMonitorSearch/ValetRequestMonitorSearch";
import Title from "../Title";
import { REQUEST_MONITOR_REMOVE_REQUEST_TYPE } from "../../constants";

// Hooks
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useCancellationToken from "../../hooks/useCancellationToken";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useHasPermissions from "../../hooks/useHasPermissions";

// Redux actions
import apiClient from "../../auth/apiClient";
import TreeService from "../../services/TreeService";

// Styles
import { useStyles } from "./ValetRequestMonitor.styles";

function getRowsByHeight(height) {
  return Math.round(height / 77);
}

const ValetRequestMonitor = () => {
  const classes = useStyles();
  const { height } = useWindowDimensions();

  const treeService = new TreeService(apiClient);

  const enqueueSnackbar = useEnqueueSnackbar();
  const {
    facilityID: entityID,
    facilityName: entityName,
  } = useCurrentFacility();
  const { hasPermissions } = useHasPermissions();
  const canEditRequestMonitor = hasPermissions(["requestmonitor.edit"]);

  // State management
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeSearch, setActiveSearch] = useState({
    removeRequestAfter: REQUEST_MONITOR_REMOVE_REQUEST_TYPE.DELIVERY,
    requestScope: [entityID],
    numberOfRequests: 25,
  });

  const [results, setResults] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const [requestScopeOptions, setRequestScopeOptions] = useState([]);
  const facilityGroupId = useSelector(
    (state) => state.entityScope?.facilityGroupId
  );
  const requests = useSelector((state) => state.requestMonitor.requests);
  const previousRequestsRef = useRef(requests);

  const getChildValetAreaList = useCallback(async () => {
    try {
      if (!facilityGroupId) {
        const scopeOptions = [{ name: entityName, id: entityID }];
        setRequestScopeOptions(scopeOptions);
        return;
      }
      const result = await treeService.getTreeStructure(facilityGroupId, 2);
      const valetAreas = result.data[0].children.filter(
        (item) => item.entitytype === "ValetArea"
      );
      const scopeOptions = valetAreas.map((area) => ({
        name: area.entityname,
        id: area.entityid,
      }));
      setRequestScopeOptions(
        scopeOptions.sort((a, b) => a.name.localeCompare(b.name))
      );
    } catch (err) {
      enqueueSnackbar("Failed to load valet areas", {
        variant: "error",
        tag: "FailedToLoadValetAreas",
      });
    }
  }, [facilityGroupId, enqueueSnackbar]);

  const executeSearchQuery = useCancellationToken({
    func: async ({ cancelToken }) => {
      setLoading(true);
      try {
        let filteredRequests = requests;
        // Filter by removeRequestAfter Delivery/Retrieval
        if (activeSearch.removeRequestAfter === REQUEST_MONITOR_REMOVE_REQUEST_TYPE.RETRIEVAL) {
          filteredRequests = filteredRequests.filter(
            (request) => !request.retrievedDate
          );
        }

        // Filter by requestScope
        if (!activeSearch.requestScope.includes("All")) {
          filteredRequests = filteredRequests.filter((request) =>
            activeSearch.requestScope.includes(request.entityID.toString())
          );
        }

        // Limit the number of requests
        filteredRequests = filteredRequests.slice(
          0,
          activeSearch.numberOfRequests
        );

        setResults(filteredRequests);
        setTotalCount(filteredRequests.length);
      } catch {
        enqueueSnackbar("Failed to load Data", {
          variant: "error",
          tag: "FailedToLoadData",
        });
      } finally {
        setLoading(false);
      }
    },
  }).execute;

  // Handle search state update and reset current page to 1
  const onApply = (searchState) => {
    setActiveSearch(searchState);
    setCurrentPage(1);
  };

  useEffect(() => {
    getChildValetAreaList();
  }, [facilityGroupId]);

  // Effect to trigger search query on entityID or activeSearch change
  useEffect(() => {
    executeSearchQuery();
  }, [entityID, activeSearch]);

  // Effect to trigger search query on requests change
  useEffect(() => {
    if (!isEqual(previousRequestsRef.current, requests)) {
      previousRequestsRef.current = requests;
      executeSearchQuery();
    }
  }, [requests]);

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  return (
    <Grid container className={clsx(classes.root)}>
      <Grid item xs={12} className={clsx(classes.header)}>
        <Title className={classes.title}>Request Monitor</Title>
      </Grid>

      <ValetRequestMonitorSearch
        onApply={onApply}
        loading={loading}
        requestScopeOptions={requestScopeOptions}
        currentSelectedEntity={entityID}
        canEditRequestMonitor={canEditRequestMonitor}
      />

      <Grid container className={clsx(classes.infoContainer)}>
        <Grid item xs={12}>
          {loading ? (
            <div className={clsx(classes.loadingWrapper)}>
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className={classes.skeleton}
                  animation="wave"
                />
              ))}
            </div>
          ) : (
            <>
              <Divider className={classes.resultsDivider} />
              {totalCount === 0 && (
                <div
                  className={clsx([
                    "animate__animated",
                    "animate__flipInX",
                    "results",
                    "not-found",
                    classes.noResults,
                  ])}
                >
                  <span className={clsx(["no-results-text"])}>
                    No active requests
                  </span>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        spacing={2}
        xs={12}
        className={clsx(classes.tableContainer)}
      >
        {!loading && totalCount > 0 && (
          <>
            <Pagination
              className={clsx(classes.pagination)}
              count={Math.ceil(totalCount / itemLimit)}
              onChange={(_, value) => setCurrentPage(value)}
              page={currentPage}
              shape="rounded"
              color="primary"
            />
            <ValetRequestMonitorTable
              results={results.slice(
                (currentPage - 1) * itemLimit,
                currentPage * itemLimit
              )}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ValetRequestMonitor;