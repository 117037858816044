import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  resultTable: {
    margin: "10px 25px 25px 25px !important",
    width: "100%",
    borderCollapse: "collapse",
  },
  thHeader: {
    backgroundColor: theme.palette.header,
    color: theme.palette.amano.base.main,
    fontWeight: "bold",
  },
  centerAlign: {
    textAlign: "center",
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));
