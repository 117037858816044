import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  sBlock: {
    marginTop: 2,
    marginBottom: 10,
  },
  notFatSelect: {
    backgroundColor: theme.palette.background.paper,
    width: "98%",
    "& .MuiOutlinedInput-input": {
      paddingBottom: "none !important",
      padding: "10px !important",
    },
  },
  applyButtonRow: {
    textAlign: "right",
  },
  applyButton: {
    float: "right",
    marginRight: 10,
    marginTop: 5,
  },
}));
