import React from "react";
import clsx from "clsx";
import moment from "moment-timezone";
import { useStyles } from "./ValetRequestMonitorTable.styles";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const ValetRequestMonitorTable = ({ results = [] }) => {
  const classes = useStyles();
  const { timeZone } = useCurrentFacilityTimezone();

  return (
    <table className={clsx(classes.resultTable, "a1-responsive")}>
      <thead className={clsx(classes.thHeader)}>
        <tr>
          <th className={clsx([classes.resultHeader])}>Ticket Number</th>
          <th className={clsx([classes.resultHeader])}>Wait Time (Mins)</th>
          <th className={clsx([classes.resultHeader])}>Payment Status</th>
          <th className={clsx([classes.resultHeader])}>License Plate</th>
          <th className={clsx([classes.resultHeader])}>Valet Area</th>
          <th className={clsx([classes.resultHeader])}>Parked Space</th>
          <th className={clsx([classes.resultHeader])}>Customer</th>
          <th className={clsx([classes.resultHeader])}>Retrieval Employee</th>
        </tr>
      </thead>

      <tbody>
        {results.map((result, index) => {
          const requestedTime = moment.utc(result.requestedDate).tz(timeZone);
          const currentTime = moment().tz(timeZone);
          const waitTimeMinutes = currentTime.diff(requestedTime, "minutes");

          return (
            <tr
              key={result.ticketNumber}
              data-testid="requestmonitor-row"
            >
              <td
                data-column="Ticket Number"
                className={clsx([classes.column])}
              >
                {result.ticketNumber}
              </td>
              <td data-column="Wait Time" className={clsx([classes.column])}>
                {waitTimeMinutes}
              </td>
              <td
                data-column="Payment Status"
                className={clsx([classes.column])}
              >
                <CheckCircleIcon className={clsx([classes.checkIcon])} />
              </td>
              <td
                data-column="License Plate"
                className={clsx([classes.column])}
              >
                {result.licensePlate}
              </td>
              <td data-column="Valet Area" className={clsx([classes.column])}>
                {result.entityName}
              </td>
              <td data-column="Parked Space" className={clsx([classes.column])}>
                {result.parkedSpace}
              </td>
              <td data-column="Customer" className={clsx([classes.column])}>
                {[result.firstName, result.lastName].filter(Boolean).join(" ")}
              </td>
              <td
                data-column="Retrieval Employee"
                className={clsx([classes.column])}
              >
                {result.retrievedDate ? "unassigned" : ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ValetRequestMonitorTable;
