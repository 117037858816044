import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./ValetRequestMonitorSearch.styles";
import SelectableChip from "../../SelectableChip";
import { REQUEST_MONITOR_REMOVE_REQUEST_TYPE } from "../../../constants";

const ValetRequestMonitorSearch = ({
  onApply,
  loading,
  requestScopeOptions,
  currentSelectedEntity,
  canEditRequestMonitor
}) => {
  const classes = useStyles();
  const [removeRequestAfter, setRemoveRequestAfter] = useState(REQUEST_MONITOR_REMOVE_REQUEST_TYPE.DELIVERY);
  const [requestScope, setRequestScope] = useState([currentSelectedEntity]);
  const [numberOfRequests, setNumberOfRequests] = useState(25);
  const handleRequestScopeChange = (scopeId) => {
    setRequestScope((prev) => {
      if (scopeId === "All") {
        return ["All"];
      } else {
        const newScope = prev.includes(scopeId)
          ? prev.filter((item) => item !== scopeId)
          : [...prev.filter((item) => item !== "All"), scopeId];
        return newScope.length === 0 ? ["All"] : newScope;
      }
    });
  };
  const handleApply = () => {
    onApply({
      removeRequestAfter,
      requestScope,
      numberOfRequests,
    });
  };

  return (
    <>
      <Grid container item xs={12} md={6}>
        <Grid
          container
          item
          xs={12}
          md={12}
          className={clsx([classes.sBlock, "type-container"])}
        >
          <Grid item xs={3} md={3}>
            <Typography className={clsx(["type-title"])}>
              Remove Request After
            </Typography>
          </Grid>
          <Grid item xs={9} md={9}>
            <SelectableChip
              text="Retrieval"
              className={clsx(["type-option", "Retrieval"])}
              variant="primary"
              onClick={() => setRemoveRequestAfter(REQUEST_MONITOR_REMOVE_REQUEST_TYPE.RETRIEVAL)}
              selected={removeRequestAfter === REQUEST_MONITOR_REMOVE_REQUEST_TYPE.RETRIEVAL}
              disabled={!canEditRequestMonitor}
            />
            <SelectableChip
              text="Delivery"
              className={clsx(["type-option", "Delivery"])}
              variant="primary"
              onClick={() => setRemoveRequestAfter(REQUEST_MONITOR_REMOVE_REQUEST_TYPE.DELIVERY)}
              selected={removeRequestAfter === REQUEST_MONITOR_REMOVE_REQUEST_TYPE.DELIVERY}
              disabled={!canEditRequestMonitor}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={12}
          className={clsx([classes.sBlock, "scope-container"])}
        >
          <Grid item xs={3} md={3}>
            <Typography className={clsx(["scope-title"])}>
              Request Scope
            </Typography>
          </Grid>
          <Grid item xs={9} md={9}>
            {requestScopeOptions.length > 1 && (
              <>
                <SelectableChip
                  text="All"
                  size="small"
                  variant="secondary"
                  onClick={() => handleRequestScopeChange("All")}
                  className={clsx({ selected: requestScope.includes("All") })}
                  selected={requestScope.includes("All")}
                  title="all entries, regardless of scope"
                  disabled={!canEditRequestMonitor}
                />
                <Divider orientation="vertical" flexItem />
              </>
            )}
            {requestScopeOptions.map((scope) => (
              <SelectableChip
                text={scope.name}
                key={scope.id}
                size="small"
                variant="secondary"
                onClick={() => handleRequestScopeChange(scope.id)}
                className={clsx({ selected: requestScope.includes(scope.id) })}
                selected={requestScope.includes(scope.id)}
                title={scope.name}
                disabled={!canEditRequestMonitor}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={6}
        className={clsx(["setting-params-container"])}
      >
        <Grid
          container
          item
          xs={12}
          md={12}
          className={clsx([classes.sBlock, "setting-criteria-container"])}
        >
          <Grid item xs={3} md={3}>
            <Typography className={clsx(["setting-criteria-title"])}>
              Number of Requests
            </Typography>
          </Grid>
          <Grid item xs={9} md={9}>
            <Select
              className={clsx([classes.notFatSelect, "filter-type"])}
              variant="outlined"
              size="small"
              value={numberOfRequests}
              onChange={(e) => setNumberOfRequests(e.target.value)}
              disabled={!canEditRequestMonitor}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={250}>250</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} className={clsx([classes.applyButtonRow])}>
          <Button
            disabled={loading || !canEditRequestMonitor}
            color="primary"
            className={clsx(["button", "apply", classes.applyButton])}
            variant="contained"
            onClick={handleApply}
          >
            APPLY
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ValetRequestMonitorSearch;
